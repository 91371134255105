<template>
  <div id="External" class="login fullheight" :class="{ 'c-dark-theme': $store.state.darkMode }">
    <CContainer fluid class="fullheight"> 
      <CRow class="align-start align-md-center fullheight">
        <CCol cols="12" md="12" class="my-4 my-sm-8 my-md-0" style="margin-top: 20px !important;">
          <CCardBody>
            <CDataTable
              hover
              striped
              border
              fixed
              :items="students"
              :fields="fields"
              :items-per-page="50"
              pagination
              :tableFilter="{label: 'Buscar: ', placeholder: ' '}"
            >
              <template #position="{item}"><td class="w100 text-center">{{item.position}}</td></template>              
              <template #name="{item}"><td style="text-transform: uppercase;">{{item.name}}</td></template>              
              <template #passport="{item}">
                <td class="table_actions w100">    
                  <CButton v-if="item.passport_filename != ''" color="info" size="sm" @click="showModal(item.passport_filename)" v-c-tooltip="{content: 'Ver', placement: 'top'}">Ver</CButton><br />
                </td>
              </template>
              <template #visa="{item}">
                <td class="table_actions w100">    
                  <CButton v-if="item.visa_filename != ''" color="success" size="sm" @click="showModal(item.visa_filename)" v-c-tooltip="{content: 'Ver', placement: 'top'}">Ver</CButton><br />
                </td>
              </template>
            </CDataTable>              
          </CCardBody>
        </CCol>
      </CRow>
    </CContainer>
    <DocumentModal ref="documentModal"></DocumentModal>
    <loading :active="visible" :can-cancel="true"></loading>
  </div>
</template>

<script>
import ws from '../../services/students';
import store from '../../store';
import DocumentModal from '../../components/travels/students/DocumentModal.vue'
import Loading from 'vue-loading-overlay/dist/vue-loading.js';;
import 'vue-loading-overlay/dist/vue-loading.css';
import axios from 'axios'

export default {
  name: 'StudentsExternal',
  components: {
    Loading,
    DocumentModal
  },
  data: function () {
      return {
          students: [],
          code: "",
          fields: [
            {key: 'position', label: 'No.'},
            {key: 'name', label: 'Nombre del Alumno'},
            {key: 'passport', label: 'Pasaporte',sorter: false, filter: false},
            {key: 'visa', label: 'Visa',sorter: false, filter: false},
          ],
      }
  },
  mounted: async function() {
    this.loading();
      this.code = await this.$route.params.code;

      let response = await ws.getStudentsExternal(this.code); 

      if(response.type == "success"){
        this.students = response.data;
      }

      let navegador = navigator.userAgent;
      if (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/webOS/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/BlackBerry/i) || navigator.userAgent.match(/Windows Phone/i)) {
          this.desktop = false;
      } else {
          this.desktop = true;
      }
    this.loaded();  
  },
  methods: {
    showModal: async function(url){
      if(url.slice(-4) == ".pdf"){
        var link = document.createElement('a');
        link.href = url;
        link.click();
      }else{
        this.$refs.documentModal.show(url);
      }      
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  },
  computed: {      
    visible() {
      return store.state.isLoading;
    },
  },  
}
</script>
