<template>
  <CModal
      :title="title"
      :color="color"
      size="xl"
      :show.sync="documentModal"
    >   
      <CRow>
        <CCol md="12">
          <img :src="url" alt="" width="1100px" @load="load">
        </CCol>
      </CRow>
    <template #footer>
      <CButton @click="closeModal" :color="color">Cerrar</CButton>
    </template>
  </CModal>
</template>

<script>
import store from '../../../store';
import Loading from 'vue-loading-overlay/dist/vue-loading.js';;
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  name: 'DivideModal',
  components: {
    Loading,
  },
  data () {
    return {
      documentModal: false,
      title: "Documento",
      color: "dark",
      url: "",
    }
  },
  mounted: async function() {
  },
  methods: {
    show (url) {
      this.loading();
      this.url = url;
      this.documentModal = true;
    },
    closeModal () {
      this.url = "";
      this.documentModal = false;      
    },
    load () {
      this.loaded();        
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }    
    
  },
  computed: {      
    visible() {
      return store.state.isLoading;
    },
  },  
}
</script>